$(".br1").click(function(){
  $(".car1").toggleClass("active");
});

$(".br2").click(function(){
  $(".car2").toggleClass("active");
});

$(".br3").click(function(){
  $(".car3").toggleClass("active");
});



// Changing the defaults
    window.sr = ScrollReveal({ reset: false, mobile: false });

    // Customizing a reveal set
        sr.reveal('.foo', { duration: 1000 });
        sr.reveal('.lft', { origin: 'left', distance: '200px', delay: '100' });
        sr.reveal('.rght2', { origin: 'right', distance: '100px', duration: 1000 });
        sr.reveal('.rght', { origin: 'right', distance: '200px', delay: '100'});
        sr.reveal('.bot1', { origin: 'bottom', distance: '100px', duration: 1000 }, 100);
        sr.reveal('.bot2', { origin: 'bottom', distance: '200px', duration: 2000 }, 200);
        sr.reveal('.bot3', { origin: 'bottom', distance: '300px', duration: 3000 }, 300);
        sr.reveal('.bot', { origin: 'bottom', distance: '500px', duration: 2000 }, 100);
        sr.reveal('.box', { duration: 2000 }, 100);
        sr.reveal('.int', { interval: 300 });

/* --------- Ancres animées --------- */

$(document).on('click', 'a[href^="#"]:not([data-toggle="collapse"], [data-toggle="tab"])', function (event) {
    event.preventDefault();

    $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top
    }, 800);
});

$(document).ready(function(){

    /* --------- Sous-Menu / open Bootstrap 4 dropdown on hover --------- */
    $('.primaryMenu .dropdown-toggle').click(function(e) {
      if ($(document).width() > 768) {
        e.preventDefault();

        var url = $(this).attr('href');

        if (url !== '#') {
          window.location.href = url;
        }
      }
    });

	/* --------- retour en haut de page --------- */
	$(window).scroll(function() {
	  if($(window).scrollTop() == 0){
	    $('#scrollToTop').fadeOut("fast");
	  } else {
	    $('#scrollToTop').fadeIn("fast");
	  }
	});

	$('#scrollToTop').on('click', function(event){    // remplacer .live par .on depuis jquery 1.10
	  event.preventDefault();
	  $('html,body').animate({scrollTop: 0}, 'slow');
	});

	/* --------- target _blank --------- */
	$("a.lien_ext").click(function(){
		this.target = "_blank";
	});

	/* --------- Mentions legales ---------
	$('#mentions').click(function() { $('#mentions-bloc').fadeIn("fast"); });
	$('#close_mentions').click(function() { $('#mentions-bloc').fadeOut("fast"); });
  */

  /* --------- jQuery CookieBar --------- */
  // $.cookieBar({
  //   message: 'Ce site utilise des cookies. En naviguant sur notre site vous acceptez l\'installation et l\'utilisation des cookies sur votre ordinateur. <a href="/politique-de-confidentialite" class="btn-warning">En savoir plus</a>',
  //   acceptText: 'OK',
  //   fixed: true,
  //   bottom: true
  // });

	/* --------- Affiche la galerie quand le chargement est fini ---------
	$("#contenuGalerie").fadeIn("slow");
	$("#loadingContent").hide();
    */

  /* --------- Toogle WebRappel--------- */
	$('#toggleRappel').click(function() { $('.widget_rappel').slideDown(); });
	$('#closeRappel').click(function() { $('.widget_rappel').slideUp(); })

  /* --------- Demo Assets --------- */
  $(window).scroll(function () {
      var top = $(document).scrollTop();
      $('.splash').css({
        'background-position': '0px -'+(top/3).toFixed(2)+'px'
      });
      if(top > 50)
        $('#home > .navbar').removeClass('navbar-transparent');
      else
        $('#home > .navbar').addClass('navbar-transparent');
  });

  $("a[href='#']").click(function(e) {
    e.preventDefault();
  });

  var $button = $("<div id='source-button' class='btn btn-primary btn-xs'>&lt; &gt;</div>").click(function(){
    var html = $(this).parent().html();
    html = cleanSource(html);
    $("#source-modal pre").text(html);
    $("#source-modal").modal();
  });

  $('.bs-component [data-toggle="popover"]').popover();
  $('.bs-component [data-toggle="tooltip"]').tooltip();

  $(".bs-component").hover(function(){
    $(this).append($button);
    $button.show();
  }, function(){
    $button.hide();
  });

  function cleanSource(html) {
    html = html.replace(/×/g, "&close;")
               .replace(/«/g, "&laquo;")
               .replace(/»/g, "&raquo;")
               .replace(/←/g, "&larr;")
               .replace(/→/g, "&rarr;");

    var lines = html.split(/\n/);

    lines.shift();
    lines.splice(-1, 1);

    var indentSize = lines[0].length - lines[0].trim().length,
        re = new RegExp(" {" + indentSize + "}");

    lines = lines.map(function(line){
      if (line.match(re)) {
        line = line.substring(indentSize);
      }

      return line;
    });

    lines = lines.join("\n");

    return lines;
   }
});
